import { Form, useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import {
  Accordion,
  Divider,
  Heading3,
  Margin,
  Radio,
  RadioField,
  TextareaField,
} from '@ovotech/element';

import { IssueOption } from './IssueOption';
import { ErrorHint } from './ErrorHint';
import { AccordionContent } from './AccordionContent';

import { BasicPage, Basket, StyledCard } from '@components';
import { BasketNavigationButtons } from '@components/Basket/BasketNavigationButtons';
import { ScrollToFieldError } from '@components/ScrollToFieldError';
import { MixpanelEvents, RepairIssue } from '@entities/enums';
import { Quote } from '@entities/types';
import { useCustomMixpanel } from '@services/Mixpanel';
import { Repair, repairGroup, RepairProblem } from '@services/RepairService';
import { useMediaIs } from '@hooks/useMediaIs';
import { LoginCard } from '@components/LoginCard';
import { LOGGED_IN_REPAIR_COST, LOGGED_OUT_REPAIR_COST } from '@constants/homeRepair';
import { useAuth } from '@hooks/useAuth';

const Header = styled.div``;

const SubTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
`;

const IssueOptionsSegment = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.core.space[4]};
`;

const ProblemTypesList = styled.div`
  label {
    width: 100%;
  }
`;

const AdditionalInfoSection = styled.div`
  margin-top: 14px;

  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: ${({ theme }) => theme.core.color.brand.black};
  }

  label [data-testid='error-hint'] {
    color: ${({ theme }) => theme.core.color.red.darker};
  }
`;

const AccordionWrapper = styled.div`
  ${({ theme: { semantic } }) => `
    background-color: ${semantic.surface.base};
  `}
`;

const StyledRadioField = styled(RadioField)`
  & > div:has(> [value='Uncontrollable water leak'][checked]),
  & > div:has(> [value='Something else'][checked]),
  & > div:has(> [value='Full loss of power'][checked]) {
    ${({ theme: { core } }) => `
      background-color: ${core.color.blue.lightest};
      border-radius: ${core.radius.medium};
      padding: ${core.space[0]} ${core.space[2]};
    `}
  }
`;

interface Props {
  quote: Quote;

  onClickBack(): void;
}

const fieldsInOrder: (keyof Repair)[] = ['repairIssue', 'problemType', 'confirm', 'notes'];

export const RepairDetailsForm = ({ quote, onClickBack }: Props) => {
  const { handleChange, values, setFieldValue, isSubmitting, errors, setErrors } =
    useFormikContext<Repair>();
  const { track } = useCustomMixpanel();
  const isMobile = useMediaIs('mobile', 'max');
  const { user, loading } = useAuth();

  useEffect(() => {
    if (errors.repairIssue) {
      track(MixpanelEvents.ERROR, {
        error: errors.repairIssue,
        element: 'RepairIssue',
      });
    }
  }, [errors.repairIssue, track]);

  // Available problem types for repair issue
  const problemTypes = useMemo(() => {
    if (!values.repairIssue) {
      return [];
    }
    return repairGroup.get(values.repairIssue) ?? [];
  }, [values.repairIssue]);

  // Selected repair problem
  const repairProblem: RepairProblem | undefined = useMemo(
    () => problemTypes.find((_) => _.problemType === values.problemType),
    [problemTypes, values.problemType]
  );
  const blockedSale = repairProblem?.blockedSale ?? true;
  const needAdditionalInfo = repairProblem?.needAdditionalInfo ?? false;

  const hasFreeRepairOffer = quote?.promotions.some((offer) => offer.type === 'FREEREPAIR');

  const isLoggedIn = Boolean(user?.accountId && !loading);

  const repairCost =
    isLoggedIn || hasFreeRepairOffer ? LOGGED_IN_REPAIR_COST : LOGGED_OUT_REPAIR_COST;

  return (
    <Form data-testid={'repair-details-form'} onChange={() => setErrors({})}>
      <ScrollToFieldError<Repair> fieldsInOrder={fieldsInOrder} />
      <BasicPage.Content>
        <BasicPage.Left>
          <LoginCard />
          <Margin vertical={3} />
          <StyledCard>
            <Header>
              <Heading3>Tell us about your issue</Heading3>
              <Margin top={2} bottom={6}>
                <SubTitle>Please choose one of these options.</SubTitle>
              </Margin>
            </Header>
            <RadioField
              label={''}
              defaultValue={values.repairIssue}
              id={'repairIssue'}
              name={'issue'}
              error={errors.repairIssue}
              onChange={(e) => {
                handleChange(e);
                setFieldValue('problemType', undefined);
                const { value } = e.target as HTMLInputElement;
                track(MixpanelEvents.OPTION_SELECT, {
                  element: 'RepairType',
                  value,
                });
              }}
            >
              <IssueOptionsSegment>
                <IssueOption
                  repairIssue={RepairIssue.BOILER_AND_CENTRAL_HEATING}
                  id={'boiler'}
                  iconName={'boiler-service'}
                  defaultChecked={values.repairIssue === RepairIssue.BOILER_AND_CENTRAL_HEATING}
                />
                <IssueOption
                  repairIssue={RepairIssue.INTERNAL_DRAINS_AND_WASTE_PIPES}
                  id={'internal-drains-waste-pipes'}
                  iconName={'internal-drains'}
                  defaultChecked={
                    values.repairIssue === RepairIssue.INTERNAL_DRAINS_AND_WASTE_PIPES
                  }
                />
                <IssueOption
                  repairIssue={RepairIssue.PLUMBING}
                  id={'water'}
                  iconName={'plumbing'}
                  defaultChecked={values.repairIssue === RepairIssue.PLUMBING}
                />
                <IssueOption
                  repairIssue={RepairIssue.ELECTRICS}
                  id={'electronics'}
                  iconName={'electric'}
                  defaultChecked={values.repairIssue === RepairIssue.ELECTRICS}
                />
              </IssueOptionsSegment>
            </RadioField>
            {problemTypes.length > 0 && (
              <>
                <Margin top={6} bottom={6}>
                  <Divider type={'differentiated'} />
                </Margin>
                <Header>
                  <Heading3>Tell us a bit more</Heading3>
                  <Margin vertical={2} />
                  <SubTitle>
                    Please tell us about your problem by choosing an option from the list below
                  </SubTitle>
                </Header>
                <ProblemTypesList>
                  <StyledRadioField
                    label={''}
                    id={'problemType'}
                    name={'problemType'}
                    error={errors.problemType}
                    onChange={(e) => {
                      handleChange(e);
                      const { value } = e.target as HTMLInputElement;
                      track(MixpanelEvents.OPTION_SELECT, {
                        element: 'ProblemType',
                        value,
                      });
                    }}
                  >
                    {problemTypes.map(({ problemType, dynamicMessage, question }, index) => (
                      <Radio
                        data-testid={problemType}
                        style={{ width: '100%' }}
                        id={`problemType-${index}`}
                        key={`${values.repairIssue}-${problemType}`}
                        label={problemType}
                        value={problemType}
                        onChange={handleChange}
                        defaultChecked={problemType === values.problemType}
                        hint={
                          values.problemType === problemType ? (
                            <ErrorHint
                              showYesNo={!blockedSale}
                              title={question}
                              error={dynamicMessage}
                            />
                          ) : null
                        }
                      />
                    ))}
                  </StyledRadioField>
                </ProblemTypesList>
                <AdditionalInfoSection>
                  <TextareaField
                    onChange={handleChange}
                    defaultValue={values.notes}
                    name={'notes'}
                    fullWidth
                    label={`Additional fault  / property access information ${
                      needAdditionalInfo ? '*' : '(optional)'
                    }`}
                    id={'notes'}
                    hint={errors.notes ? <ErrorHint error={errors.notes} /> : null}
                    error={errors.notes ? ' ' : undefined}
                    maxLength={226}
                  />
                </AdditionalInfoSection>
                {values.repairIssue !== undefined && (
                  <>
                    <Margin top={6} bottom={6}>
                      <Divider type={'differentiated'} />
                    </Margin>
                    <AccordionWrapper>
                      <Accordion title={"What we won't repair"}>
                        <AccordionContent repairIssue={values.repairIssue} />
                      </Accordion>
                    </AccordionWrapper>
                  </>
                )}
              </>
            )}
            {!isMobile && (
              <>
                <Margin top={6} bottom={6}>
                  <Divider type={'differentiated'} />
                </Margin>
                <BasketNavigationButtons
                  isLoading={isSubmitting}
                  nextText="Go to personal details"
                  coverType={quote.coverType}
                  backButtonConfig={{
                    text: 'Back',
                    onClick: onClickBack,
                  }}
                />
              </>
            )}
          </StyledCard>
        </BasicPage.Left>
        <BasicPage.Right>
          <Basket
            quote={quote}
            isSubmitting={false}
            nextText={'Continue'}
            repairCost={repairCost}
          />
          {isMobile && (
            <>
              <Margin top={8} />
              <BasketNavigationButtons
                isLoading={isSubmitting}
                nextText="Go to personal details"
                coverType={quote.coverType}
                backButtonConfig={{
                  text: 'Back',
                  onClick: onClickBack,
                }}
              />
            </>
          )}
        </BasicPage.Right>
      </BasicPage.Content>
    </Form>
  );
};
