import { PHONE_NUMBER, ProblemType, RepairIssue, WORKING_HOURS } from '@entities/enums';
import { repairAtom, store } from '@src/store/store';

const NOT_REPAIRS_MSG = "We're sorry but we don't currently offer repairs for anything else.";

export interface RepairProblem {
  problemType: ProblemType;
  dynamicMessage: string | null;
  needAdditionalInfo: boolean;
  blockedSale: boolean;
  blockedSaleIfNo: boolean; // Some sales are blocked if customer answers no on question
  question?: string;
}

export const emptyRepairProblem: RepairProblem = {
  problemType: ProblemType.SOMETHING_ELSE,
  dynamicMessage: null,
  needAdditionalInfo: false,
  blockedSale: false,
  blockedSaleIfNo: false,
  question: '',
};

export const buildProblem = (
  problemType: ProblemType,
  dynamicMessage: string | null = null,
  needAdditionalInfo = false,
  blockedSale = false,
  blockedSaleIfNo = false,
  question = ''
): RepairProblem => ({
  problemType,
  dynamicMessage,
  needAdditionalInfo,
  blockedSale,
  blockedSaleIfNo,
  question,
});

export const repairGroup: Map<RepairIssue, RepairProblem[]> = new Map([
  [
    RepairIssue.BOILER_AND_CENTRAL_HEATING,
    [
      buildProblem(
        ProblemType.NO_CENTRAL_HEATING,
        ' ',
        false,
        false,
        false,
        'Is there anyone in the property dependant on central heating or hot water?'
      ),
      buildProblem(
        ProblemType.NO_HOT_WATER,
        ' ',
        false,
        false,
        false,
        'Is there anyone in the property dependant on central heating or hot water?'
      ),
      buildProblem(ProblemType.LEAKING_BOILER),
      buildProblem(ProblemType.BOILER_PRESSURE),
      buildProblem(ProblemType.RADIATOR_NOT_WORKING),
      buildProblem(ProblemType.LEAKING_RADIATOR),
      buildProblem(ProblemType.SOMETHING_ELSE, null, true),
    ],
  ],
  [
    RepairIssue.INTERNAL_DRAINS_AND_WASTE_PIPES,
    [
      buildProblem(ProblemType.BLOCKED_INTERNAL_DRAIN_OR_WASTEPIPE),
      buildProblem(ProblemType.LEAKING_INTERNAL_DRAIN_OR_WASTEPIPE),
      buildProblem(ProblemType.SOMETHING_ELSE, NOT_REPAIRS_MSG, false, true),
    ],
  ],
  [
    RepairIssue.PLUMBING,
    [
      buildProblem(
        ProblemType.UNCONTROLLABLE_WATER_LEAK,
        `Turn your water off at the stopcock and call us on ${PHONE_NUMBER}. We'll be happy to help. (${WORKING_HOURS})`,
        false,
        true,
        true
      ),
      buildProblem(ProblemType.LEAKING_TOILET),
      buildProblem(ProblemType.FLUSH_NOT_WORKING),
      buildProblem(ProblemType.LEAKING_TAP),
      buildProblem(
        ProblemType.LEAKING_PIPES,
        "If you can't see the source of the leak (e.g. underfloor, within walls or ceilings), you'll need to arrange access before we can repair.",
        false,
        false,
        true,
        'Have you identified the source of the leak?'
      ),
      buildProblem(ProblemType.SOMETHING_ELSE, NOT_REPAIRS_MSG, false, true),
    ],
  ],
  [
    RepairIssue.ELECTRICS,
    [
      buildProblem(
        ProblemType.FULL_LOSS_OF_POWER,
        `Please call us on ${PHONE_NUMBER}. We'll be happy to help. (${WORKING_HOURS})`,
        false,
        true
      ),
      buildProblem(ProblemType.CIRCUIT_BREAKER_TRIPPING),
      buildProblem(ProblemType.LIGHTSWITCH_FAULT),
      buildProblem(ProblemType.SOCKET_FAULT),
      buildProblem(ProblemType.SOMETHING_ELSE, NOT_REPAIRS_MSG, false, true),
    ],
  ],
]);

export const problemTypeMap: Map<ProblemType | undefined, string> = new Map([
  [ProblemType.NO_CENTRAL_HEATING, 'No Heating'],
  [ProblemType.NO_HOT_WATER, 'No Hot Water'],
  [ProblemType.LEAKING_BOILER, 'Leak - Boiler'],
  [ProblemType.BOILER_PRESSURE, 'Boiler Pressure Hi/Lo'],
  [ProblemType.RADIATOR_NOT_WORKING, 'Radiator Not Heating'],
  [ProblemType.LEAKING_RADIATOR, 'Leak - Radiator'],
  [ProblemType.SOMETHING_ELSE, 'See jobsheet'],
  [ProblemType.CIRCUIT_BREAKER_TRIPPING, 'Circuit Breaker Tripping'],
  [ProblemType.LIGHTSWITCH_FAULT, 'Lightswitch Fault'],
  [ProblemType.SOCKET_FAULT, 'Socket Fault'],
  [ProblemType.LEAKING_TOILET, 'Leaking WC'],
  [ProblemType.FLUSH_NOT_WORKING, 'Faulty Syphon/ Ballcock'],
  [ProblemType.LEAKING_TAP, 'Leaking Tap'],
  [ProblemType.LEAKING_PIPES, 'Leak - Plumbing'],
  [ProblemType.BLOCKED_INTERNAL_DRAIN_OR_WASTEPIPE, 'Blocked Drainage Internal'],
  [ProblemType.LEAKING_INTERNAL_DRAIN_OR_WASTEPIPE, 'Leak - Drainage Internal'],
]);

export interface Repair {
  cost?: number;
  repairIssue?: RepairIssue;
  problemType?: ProblemType;
  confirm?: 'yes' | 'no';
  notes?: string;
}

class RepairService {
  private repair: Repair = {
    confirm: 'yes',
  };

  get = () => {
    return {
      ...store.get(repairAtom),
      ...this.repair,
    };
  };

  save = (repair: Repair) => {
    this.repair = repair;
    store.set(repairAtom, repair);
  };
}

export default new RepairService();
